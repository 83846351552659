
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import {
  debounceTime,
  distinctUntilChanged,
  map,
} from "rxjs/operators";
import { Subject } from "rxjs";
import Vue from "vue";

export default {

  components: {
    Multiselect
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) );

    return {
      page: 0,
      pageCount: 0,
      itemsPerPage: 10,
      favoursSnippet: snippets ?? {},
      favoursList: [],
      favoursPaginatedList: {},
      searchedFavoursPaginatedList: {},
      searchedFavoursList: [],
      paginatedFavoursList: [],
      paginatedSearchedFavoursList: [],
      spacesList: [],
      organizationsList: [],
      organizationsNamesList: [],
      loadedPages: [],
      searchedData: [],
      searchedDataOriginal: '',
      selectedOrganization: '',
      currentManagerOrganization: '',
      searchKey: '',
      searchField: '',
      dialog: false,
      dialogDelete: false,
      dialogUpgrade: false,
      submitted: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        active: '',
        name: '',
        description: '',
        type: '',
        photoIds: '',
        availableSpaces: [],
        createDate: '',
      },
      defaultItem: {
        id: '',
        active: '',
        name: '',
        description: '',
        type: '',
        photoIds: '',
        availableSpaces: [],
        createDate: '',
      },
      sortBy: 'id',
      editedPrefix: '',
      searchResults: '',
      search$: new Subject(),
      sortDesc: true,
      errorKey: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldFavour: {},
      addressesDataList: [],
      itemsPerPageOptions: [ 0 ],
      favourData: { favours: [], count: Number },
      spaceData: [],
      totalPassengers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      canOverwrite: true,
    }
  },

  computed: {

    formTitle() {
      return this.editedIndex === -1 ? this.favoursSnippet.add_favour : this.favoursSnippet.edit_favour
    },

    headers() {
      return [
        { text: this.favoursSnippet.id, value: 'id', width: '10%', sortable: true },
        { text: this.favoursSnippet.name, value: 'name', width: '15%', sortable: true },
        {
          text: this.favoursSnippet.description,
          value: 'description',
          width: '15%',
          sortable: true
        },
        { text: this.favoursSnippet.actions, value: 'actions', width: '10%', sortable: false },
      ]
    },
  },

  watch: {

    dialog( val ) {
      val || this.close()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

    async searchKey( searchText ) {
      if ( searchText ) {
        this.search$.next( searchText );
        this.searchField = searchText
      } else {
        this.search$.next( '' );
        this.searchField = ''
        this.searchedData = [];
      }
    },

  },
  beforeRouteLeave( to, from, next ) {
    this.saveFavoursDataToSessionStorage();
    // StorageService.deleteFavourFromStorage();
    next()
  },

  async mounted() {
    this.searchAction();

    window.addEventListener( 'beforeunload', this.saveFavoursDataToSessionStorage );
    let storageFavours = this.getFavoursDataFromSessionStorage();
    if ( storageFavours?.data ) {
      this.favourData = storageFavours;
      this.loadedPages.push( +storageFavours.currentPage );
      await this.getFavoursPaginated();
    } else {
      await this.getFavoursApi();
    }

    this.loading = false;

  },

  methods: {
    customSort( items ) {
      return items
    },

    async sortTable() {
      await Promise.resolve();
      this.changePage(this.currentPage ?? 1);
    },

    getSortQuery(comesFirst = false) {
      if (this.sortBy) {
        return `${comesFirst ? '?' : '&'}sortBy=${this.sortBy}&direction=${this.sortDesc ? 'DESC' : 'ASC'}`;
      }

      return '';
    },

    saveFavoursDataToSessionStorage() {
      //TODO: remove
      return
      if ( this.canOverwrite ) {
        sessionStorage.setItem( 'favours_paginated_list', JSON.stringify( this.favourData ) )
        sessionStorage.setItem( 'favours_searched_text', JSON.stringify( this.searchField ) )
        sessionStorage.setItem( 'favours_searched_list', JSON.stringify( this.searchedDataOriginal ) )
        sessionStorage.setItem( 'favours_paginated_list_save_date', Date.now().toString() )
      }
    },

    getFavoursDataFromSessionStorage() {
      let favoursData = JSON.parse( sessionStorage.getItem( 'favours_paginated_list' ) );
      this.searchKey = JSON.parse( sessionStorage.getItem( 'favours_searched_text' ) );
      if ( this.searchKey ) {
        favoursData = {};
      }
      this.currentPage = favoursData?.currentPage ? +favoursData.currentPage : 1;
      return favoursData;
    },


    openDetails( favour ) {
      if ( favour && favour.id ) {
        this.$router.push( `service-details/${ favour.id }` )
      }
    },

    searchAction() {
      this.search$ = new Subject();

      this.search$.pipe(
        map( text => text?.toString()?.toLowerCase() ),
        debounceTime( 1000 ),
        distinctUntilChanged()
      ).subscribe( async ( term ) => {
        this.currentPage = 1;

        if ( this.searchField === '' ) {
          await this.getFavoursPaginated();
          return
        }

        let data = await this.searchText( term );
        await this.getSearchedFavoursPaginated( data );
      } )
    },

    searchText( term ) {
      return ApiRequestService.getRequest( `api/facility-favours?searchText=${ term }${this.getSortQuery()}` );
    },

    async changePage( page ) {
      this.favoursPaginatedList = {};
      this.searchedFavoursPaginatedList = {};
      this.paginatedSearchedFavoursList.length = 0;
      this.paginatedFavoursList.length = 0;
      this.favoursList.length = 0;
      this.currentPage = page;

      if ( this.searchField ) {
        this.searchedDataOriginal = await ApiRequestService.getRequest( `api/facility-favours?page=${ page }&searchText=${ this.searchField }${this.getSortQuery()}` );


        if ( this.searchedDataOriginal?.data?.length ) {
          await this.setSearchedFavoursToFavoursList( this.searchedDataOriginal.data );
        }
        return;
      }

      this.favourData = await ApiRequestService.getRequest( `api/facility-favours?page=${ page }${this.getSortQuery()}` );
      this.loadedPages = [this.currentPage];

      if ( this.favourData?.data?.length ) {
        await this.setFavoursToFavoursList( this.favourData.data );
      }
    },

    async getFavoursApi() {
      this.favourData = await ApiRequestService.getRequest( `api/facility-favours${this.getSortQuery(true)}` );
      await this.getFavoursPaginated();
    },

    async getFavoursPaginated() {
      let favoursCount = this.favourData.totalElements;
      this.pagesCount = Math.ceil( this.favourData.totalElements / 10 )
      if ( favoursCount ) {
        await this.setFavoursToFavoursList( this.favourData.data );
      }
    },

    async getSearchedFavoursPaginated( data ) {
      this.pagesCount = Math.ceil( data.totalElements / 10 )

      this.searchedFavoursList = [];
      this.paginatedSearchedFavoursList.length = 0;
      this.searchedFavoursPaginatedList = {};
      await this.setSearchedFavoursToFavoursList( data.data );

      this.searchedDataOriginal = data;
      this.searchedData = this.getObjClone( this.searchedFavoursList )
    },

    async setFavoursToFavoursList( favourData ) {
      favourData.forEach( ( favour, index ) => {
        let existsIndex = this.favoursList.findIndex( item => item.id === favour?.id );

        let favourData = {
          id: favour?.id,
          name: favour.name,
          description: favour.description,
        };

        if ( existsIndex !== -1 ) {
          Vue.set( this.favoursList, existsIndex, favourData )

          let values = this.favoursPaginatedList[this.currentPage] ? this.favoursPaginatedList[this.currentPage].values() : [];
          let favoursPaginatedListClone = [ ...values ];
          favoursPaginatedListClone.forEach( item => {
            if ( item?.id === favourData?.id ) {
              this.favoursPaginatedList[this.currentPage].delete( item )
              this.favoursPaginatedList[this.currentPage].add( favourData )
            }
          } );
          return;
        }

        Vue.set( this.favoursList, this.favoursList.length, favourData )

        if ( !this.favoursPaginatedList[this.currentPage] ) {
          this.favoursPaginatedList[this.currentPage] = new Set();
        }
        if ( this.favoursPaginatedList[this.currentPage].size < 10 ) {
          this.favoursPaginatedList[this.currentPage].add( favourData );
        }
      } )

      if ( this.favoursPaginatedList[this.currentPage] ) {
        this.paginatedFavoursList = this.getObjClone( [ ...this.favoursPaginatedList[this.currentPage] ] )
      }
    },

    async setSearchedFavoursToFavoursList( data ) {
      data.forEach( ( favour, index ) => {
        let existsIndex = this.paginatedSearchedFavoursList.findIndex( item => item.id === favour?.id );
        let favourData = {
          id: favour?.id,
          active: favour?.active,
          name: favour?.name,
          createDate: this.getToLocaleDate( favour?.createDate ),
        };

        if ( existsIndex !== -1 ) {
          let searchedFavoursPaginatedListClone = this.getObjClone( this.searchedFavoursPaginatedList[this.currentPage] )

          searchedFavoursPaginatedListClone.forEach( ( item, index ) => {
            if ( item?.id === favourData?.id ) {
              this.searchedFavoursPaginatedList[this.currentPage][index] = favourData
            }
          } );
          return;
        }

        if ( !this.searchedFavoursPaginatedList[this.currentPage] ) {
          this.searchedFavoursPaginatedList[this.currentPage] = [];
        }
        if ( this.searchedFavoursPaginatedList[this.currentPage].length < 10 ) {
          this.searchedFavoursPaginatedList[this.currentPage].push( favourData );
        }
      } )

      if ( this.searchedFavoursPaginatedList[this.currentPage] ) {
        this.paginatedSearchedFavoursList = this.getObjClone( this.searchedFavoursPaginatedList[this.currentPage] )
      }
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    addFavourToFavourList( data ) {
      let newFavourData = {
        id: data?.id,
        active: data?.active,
        name: data?.name,
        createDate: this.getToLocaleDate( data?.createDate ),
      };

      if ( this.searchField ) {
        let existsIndex = this.paginatedSearchedFavoursList?.findIndex( favour => favour.id === newFavourData.id );
        if ( existsIndex !== -1 ) {
          this.paginatedSearchedFavoursList[existsIndex] = newFavourData;
        } else {
          this.paginatedSearchedFavoursList.push( newFavourData );
          this.searchedDataOriginal.data.totalElements++;
        }

        this.setSearchedFavoursToFavoursList( this.paginatedSearchedFavoursList )

      } else {
        let existsIndex = this.favourData?.data?.findIndex( favour => favour.id === newFavourData.id );
        if ( existsIndex !== -1 ) {
          this.favourData.data[existsIndex] = newFavourData;
        } else {
          this.favourData.data.push( newFavourData );
          this.favourData.totalElements++;
        }

        this.setFavoursToFavoursList( this.favourData.data )
      }
    },

    getChangedData( oldFavour ) {
      const dataFormUpdate = {}
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldFavour[key] === this.editedItem[key] ) {
          continue
        }


        dataFormUpdate[key] = this.editedItem[key]
      }
      return dataFormUpdate
    },

    dataIsValid() {
      const nameIsValid = this.favourIsValid( this.editedItem?.name?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    favourIsValid( v ) {
      return ( v && v.length > 0 ) || this.favoursSnippet.required
    },

    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedFavoursList : this.paginatedFavoursList;
      this.editedIndex = list.findIndex( favour => favour.id === item.id );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true
    },


    deleteItemConfirm() {
      let paginatedList = this.searchField ? this.paginatedSearchedFavoursList : this.paginatedFavoursList;
      let list = this.searchField ? this.searchedFavoursList : this.favoursList;
      paginatedList.splice( this.editedIndex, 1 )
      list.splice( this.listEditedIndex, 1 )

      ApiRequestService.deleteRequest( `api/facility-favours/${ this.editedItem.id }` ).then()

      this.closeDelete()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) )
    },

    close() {
      this.dialog = false
      this.submitted = false
      this.errorKey = ''

      /**
       * if action is edit, set to form old data
       */
      let data
      if ( this.editedIndex !== -1 ) {
        data = this.getObjClone( this.oldFavour )
      } else {
        data = this.getObjClone( this.defaultItem )
      }

      for ( let [ key, value ] of Object.entries( data ) ) {
        if ( value && typeof value === 'object' ) {
          for ( let [ valueKey, valueValue ] of Object.entries( value ) ) {
            this.$set( value, valueKey, valueValue )
          }
        } else {
          this.$set( this.editedItem, key, value )
        }
      }

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    closeDelete() {
      this.dialogDelete = false
      this.submitted = false
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    setNewFavourToFavoursList( favourData ) {
      if ( favourData ) {

        this.addFavourToFavourList( favourData )

        this.close()
      } else if ( favourData.errorMessage ) {
      }
    },

    async saveFavour() {
      let isValid = this.dataIsValid()
      if ( isValid !== true ) {
        return
      }

      let dataToCreate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( value ) {
          dataToCreate[key] = value;
        }
      }
      const favourData = await ApiRequestService.postRequest( 'api/facility-favours', dataToCreate );

      if ( favourData?.errorMessage ) {
        this.errorKey = favourData?.errorMessage?.split( ',' );
        return;
      }
      if ( !favourData ) {
        return;
      }
      this.setNewFavourToFavoursList( favourData )

    },

    save() {
      this.submitted = true

      this.saveFavour()
    },
  },
}

